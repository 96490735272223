.App {
  text-align: center;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
    filter: brightness(0) invert(1) sepia(1) saturate(10000%) hue-rotate(-20deg);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #320144;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-row {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.button-row button {
    background-color: #d4a017;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: calc(10px + 2vmin);
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-row button:hover {
    background-color: #b38614;
}

.App-subtitle {
    margin-top: 0;
}
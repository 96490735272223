.Rb-div {
    margin: 20px;
    color: white;
}

#discord {
    width: 100%;
    max-width: 500px;
}

#discord input {
    width: 20%;
    max-width: 200px;
    text-align: right;
    margin-left: 10px;
}
.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #290038;
    padding: 15px;
}

.navbar a {
    margin: 4px 5px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    padding: 5px 10px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.navbar a:hover {
    background-color: #ffffff;
    color: #290038;
}
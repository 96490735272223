.Xp-div {
    margin: 10px;
}

.Xp-div > * {
    color: white;
}

.Clickable-details summary {
    cursor: pointer;
}

.Xp-table {
    margin: 2px;
    border: 1px solid #ccc;
    border-collapse: collapse;
    /* width: 100%; */
}

.Xp-table th, .Xp-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.Xp-table th {
    background-color: #c5c5c5;
}

.Xp-inputs {
    /* margin-top: 10px;
    margin-bottom: 10px;
    max-width: 500px; */

    display: flex;
    flex-direction: column; /* Stack each label/input pair in rows */
    width: 100%;
    max-width: 500px;
}

.Xp-inputs label {
    /* display: block;
    margin-bottom: 5px;
    align-items: right; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 0; /* Optional: Adds space between rows */
}

.Xp-inputs input {
    width: 20%;
    /* box-sizing: border-box; */

    max-width: 200px; /* Adjust input box width */
    text-align: right;
    margin-left: 10px; /* Space between label text and input box */
}

/* .Xp-inputs .input-group {
    margin-bottom: 10px;
}

.Xp-inputs .input-group input {
    width: calc(100% - 10px);
} */
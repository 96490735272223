.Ingots-div {
    margin: 10px;
    min-height: 100vh;
}

.Ingots-div > * {
    color: white;
}

.Material-checklist {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
}

.Material-checklist table {
    border: 1px solid white;
    border-collapse: collapse;
    width: 100%;
}

.Material-checklist th, .Material-checklist td {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
}

.Material-checklist th {
    background-color: #333;
    color: white;
}

.Material-checklist td {
    position: relative;
}

.Material-checklist td textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    position: absolute; /* Position the textarea absolutely within the td */
    top: 0;
    left: 0;
    font-size: calc(0.5em + 1vh); /* Adjust the font size based on the height of the textarea */
}